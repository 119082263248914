import { ToastMessage } from '@ajgre/toolkit';
import { toastMessage, toastRemove } from '@cmi/store/toast';
import { createReducer, on } from '@ngrx/store';

export const toastInitialState = [] as ToastMessage[];

export const toastReducer = createReducer(
  toastInitialState,
  on(toastMessage, (state, prop) => [...state, ...[prop.toastMessage]]),
  on(toastRemove, (state, prop) => state.filter((t) => t !== prop.toastMessage))
);
