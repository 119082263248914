import { FileUploadFormConfig, IconButtonComponent, LabelComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import {
  SimpleChanges,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  OnInit,
  NgZone,
  OnDestroy,
  forwardRef,
  HostBinding
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-file-upload',
  standalone: true,
  imports: [CommonModule, LabelComponent, IconButtonComponent],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  @Input() formControlName?: string;
  @Input() formConfig!: FileUploadFormConfig;
  @Input() value: File | null = null;
  @Output() valueChange = new EventEmitter<File | null>();
  @Input() disabled = false;
  @Input() errorMessage = '';
  @ViewChild('input', { static: true }) input = {} as ElementRef<HTMLInputElement>;

  file: File | null = null;
  srcFile: string | ArrayBuffer | null = null;
  filename = '';
  filesize = 0;
  filesizeText = '';
  disabledState = false;

  showProgress = false;
  loadError = false;
  loadErrorMessage = '';
  progressPercentage = '0%';
  progressNow = 0;
  progressMax = 0;

  KB = 1024;
  MB = 1048576;

  private fileReader = new FileReader();
  private timeout?: NodeJS.Timeout;

  private propagateChange: (_: unknown) => unknown = () => null;
  private propagateTouch: (_: unknown) => unknown = () => null;

  @HostBinding('class.ng-invalid') get invalid() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }
  @HostBinding('class.ng-touched') get touched() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }

  constructor(private ngZone: NgZone) {}

  ngOnInit() {
    this.fileReader.onloadstart = (pe) => {
      this.ngZone.run(() => {
        this.showProgress = true;
        this.loadError = false;

        this.calcProgress(pe.total, pe.loaded);

        if (this.formConfig.maxFileSize && pe.total > this.formConfig.maxFileSize) {
          setTimeout(() => {
            this.calcProgress(pe.total, pe.total * 0.1);
          }, 10);

          this.displayFileSizeError();

          this.fileReader.abort();
        } else {
          this.timeout = setTimeout(() => {
            this.calcProgress(pe.total, pe.total * 0.9);
          }, 500);
        }
      });
    };
    this.fileReader.onprogress = (pe) => {
      this.ngZone.run(() => {
        clearTimeout(this.timeout);

        this.calcProgress(pe.total, pe.loaded);
      });
    };
    this.fileReader.onloadend = (pe) => {
      this.ngZone.run(() => {
        clearTimeout(this.timeout);

        if (this.loadError) {
          return;
        }

        this.calcProgress(pe.total, pe.loaded);

        setTimeout(() => {
          this.showProgress = false;
          this.srcFile = this.getFileResult();

          this.filesizeText = this.formatFileSize(pe.total, pe.loaded, false);
        }, 500);
      });
    };
    this.fileReader.onabort = () => {
      this.ngZone.run(() => {
        clearTimeout(this.timeout);

        this.clearFile();

        this.showProgress = this.loadError || false;
      });
    };
    this.fileReader.onerror = () => {
      this.ngZone.run(() => {
        clearTimeout(this.timeout);

        this.clearFile();

        this.loadError = true;
        this.loadErrorMessage = 'An error occurred while uploading this file.';
      });
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControlName) {
      if (changes['value']) {
        this.writeValue(changes['value'].currentValue);
      }
      if (changes['disabled']) {
        this.setDisabledState(changes['disabled'].currentValue);
      }
    }
  }

  displayErrorMessage(): boolean {
    return !!this.errorMessage && (this.formConfig.showErrorMessage ?? true) && !this.disabled;
  }

  writeValue(value: File | null) {
    if (value !== this.file) {
      this.file = value;
      this.showProgress = false;
      this.loadError = false;

      if (this.file) {
        this.processFile(this.file);
      }
    }
  }

  onChange(event: Event) {
    this.file = this.getFile(event);
    this.srcFile = null;

    if (this.file) {
      this.processFile(this.file);
    }

    this.propagateChange(this.file);

    this.valueChange.emit(this.file);

    this.input.nativeElement.value = '';
  }

  onDelete(event: Event) {
    this.loadError = false;
    this.filename = '';
    this.filesize = 0;

    this.clearFile();

    this.propagateTouch(event);
  }

  onAbort(event: Event) {
    if (this.loadError) {
      this.loadError = false;
      this.showProgress = false;
    } else {
      this.fileReader.abort();
    }

    this.propagateTouch(event);
  }

  onBlur(event: FocusEvent) {
    if (event.relatedTarget) {
      this.propagateTouch(event);
    }
  }

  registerOnChange(fn: (_: unknown) => unknown) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => unknown) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledState = isDisabled;
  }

  isValidExtension(filename: string): boolean {
    if (!filename || filename === '') {
      return true;
    }
    const fileExtension = filename
      .substring(filename.lastIndexOf('.'), filename.length + 1)
      .toLowerCase();
    const isValidFile: boolean =
      !!fileExtension &&
      fileExtension !== '' &&
      this.formConfig.accept?.indexOf(fileExtension) !== -1;

    return isValidFile;
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }

  private processFile(file: File) {
    this.filename = file.name;
    this.filesize = file.size;

    if (!this.isValidExtension(this.filename)) {
      this.displayFileExtensionError();

      this.clearFile();
      return;
    }

    if (this.formConfig.images) {
      this.fileReader.readAsDataURL(file);

      this.filesizeText = this.formatFileSize(file.size, 0, true);
    } else {
      if (this.formConfig.maxFileSize && file.size > this.formConfig.maxFileSize) {
        this.displayFileSizeError();
        this.clearFile();
      } else {
        this.filesizeText = this.formatFileSize(file.size, 0, false);
      }
    }
  }

  private clearFile() {
    this.file = null;
    this.srcFile = null;

    this.propagateChange(null);

    this.valueChange.emit(null);

    this.input.nativeElement.value = '';
  }

  private calcProgress(total: number, loaded: number) {
    this.progressMax = total;
    this.progressNow = loaded;
    this.progressPercentage = `${Math.round((this.progressNow / this.progressMax) * 100)}%`;

    this.filesizeText = this.formatFileSize(total, loaded, true);
  }

  private displayFileSizeError() {
    const maxFileSize = this.formatFileSize(this.formConfig.maxFileSize!, 0, false);

    this.loadError = true;
    this.loadErrorMessage = `Please upload a file less than ${maxFileSize} in size.`;
  }

  private displayFileExtensionError() {
    this.loadError = true;
    this.loadErrorMessage = `Invalid file type: Please upload a ${this.formConfig.accept} file`;
  }

  private getFile(event: Event): File | null {
    const target = event.target as { files: FileList } | null;

    return target && target.files.length > 0 ? target.files[0] : null;
  }

  private getFileResult(): string | ArrayBuffer | null {
    return this.fileReader.result;
  }

  private formatFileSize(total: number, loaded: number, uploading: boolean): string {
    if (total >= this.MB) {
      return uploading
        ? `${this.toOneDP(loaded / this.MB)} / ${this.toOneDP(total / this.MB)} MB`
        : `${this.toOneDP(total / this.MB)} MB`;
    } else if (total >= this.KB) {
      return uploading
        ? `${this.toOneDP(loaded / this.KB)} / ${this.toOneDP(total / this.KB)} KB`
        : `${this.toOneDP(total / this.KB)} KB`;
    }

    return uploading
      ? `${this.toOneDP(loaded)} / ${this.toOneDP(total)} bytes`
      : `${this.toOneDP(total)} bytes`;
  }

  private toOneDP(value: number): string {
    const b = Math.floor(value * 10) / 10;

    return Number.isInteger(b) ? b.toString() : b.toFixed(1);
  }
}
