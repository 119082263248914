import { ToastMessage } from '@ajgre/toolkit';
import { createAction, props } from '@ngrx/store';

const PREFIX = '[Toast]';

export const toastMessage = createAction(
  `${PREFIX} Message`,
  props<{ toastMessage: ToastMessage }>()
);
export const toastRemove = createAction(
  `${PREFIX} Remove`,
  props<{ toastMessage: ToastMessage }>()
);
