import { AccordionItemDirective } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, ContentChildren, Input, QueryList } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  @Input() accordionId?: string;
  @ContentChildren(AccordionItemDirective) accordionItems?: QueryList<AccordionItemDirective>;

  preventToggle = true;

  onToggle(): boolean {
    //
    // When preventToggle = TRUE, i.e. when a custom button has been clicked, this will prevent the open/close of the accordion
    //
    return !this.preventToggle;
  }

  onShow(item: AccordionItemDirective, show: boolean) {
    item.showChanged(show);
  }

  onMouseup(event: MouseEvent) {
    //
    // If a custom button in the accordion header is clicked, prevent the open/close of the accordion
    //
    this.preventToggle =
      event.target instanceof HTMLButtonElement &&
      !(event.target as HTMLButtonElement).classList.contains('accordion-button');
  }
}
