import { LabelComponent, TextareaFormConfig } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-textarea',
  standalone: true,
  imports: [CommonModule, LabelComponent],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent implements ControlValueAccessor, OnChanges {
  @Input() formControlName?: string;
  @Input() formConfig!: TextareaFormConfig;
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() errorMessage = '';
  @ViewChild('textarea', { static: true }) textarea = {} as ElementRef<HTMLTextAreaElement>;

  disabledState = false;

  private propagateChange: (_: unknown) => unknown = () => null;
  private propagateTouch: (_: unknown) => unknown = () => null;

  @HostBinding('class.ng-invalid') get invalid() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }
  @HostBinding('class.ng-touched') get touched() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControlName) {
      if (changes['value']) {
        this.writeValue(changes['value'].currentValue);
      }
      if (changes['disabled']) {
        this.setDisabledState(changes['disabled'].currentValue);
      }
    }
  }

  displayErrorMessage(): boolean {
    return !!this.errorMessage && (this.formConfig.showErrorMessage ?? true) && !this.disabled;
  }

  ariaDescribedBy(): string | undefined {
    return this.formConfig.label && this.formConfig.labelHint
      ? `${this.formConfig.id}-hint`
      : undefined;
  }

  writeValue(value: string) {
    this.textarea.nativeElement.value = value ?? '';
  }

  onChange(value: string) {
    this.propagateChange(value);

    this.valueChange.emit(value);
  }

  onBlur(event: FocusEvent) {
    this.propagateTouch(event);
  }

  registerOnChange(fn: (_: unknown) => unknown) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => unknown) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledState = isDisabled;
  }
}
