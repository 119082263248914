import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-button-group',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss'
})
export class ButtonGroupComponent {
  @Input() buttons!: string[];
  @Input() selected!: string;
  @Output() selectedChange = new EventEmitter<string>();
  @Input() ariaLabel?: string;

  onClick(selected: string) {
    this.selectedChange.emit(selected);
  }
}
