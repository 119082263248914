<div [class.re-inline-label]="formConfig.inlineLabel">
  <re-label [formConfig]="formConfig" [disabled]="disabledState"></re-label>
  <div class="re-control">
    <div>
      <input
        #input
        class="form-control"
        [class.re-icon]="!disabledState && input.value && focused"
        type="text"
        [id]="formConfig.id"
        [attr.name]="formConfig.name"
        [attr.autocomplete]="formConfig.autocomplete"
        [attr.placeholder]="formConfig.placeholder"
        [attr.aria-label]="formConfig.ariaLabel"
        [attr.aria-describedby]="ariaDescribedBy()"
        [attr.ngbautofocus]="formConfig.autoFocus ? '' : undefined"
        [disabled]="disabledState"
        (input)="onChange(input.value)"
        (focus)="onFocus()"
        (blur)="onBlur($event)" />
      <a
        *ngIf="!disabledState && input.value && focused"
        [attr.linked-id]="formConfig.id"
        href="#"
        role="button"
        aria-label="Clear"
        (click)="clear()"
        (keydown.space)="clear()"
        (blur)="onBlur($event)"
        ><img
          src="assets/images/cmi-icons/icon-reg_xmark.svg"
          alt="clear image"
          height="16"
          width="16"
      /></a>
    </div>
    <div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>
  </div>
</div>
