import { InputFormConfig, LabelComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-input',
  standalone: true,
  imports: [CommonModule, LabelComponent],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnChanges {
  @Input() formControlName?: string;
  @Input() formConfig!: InputFormConfig;
  @Input() value = '';
  @Output() valueChange = new EventEmitter<string>();
  @Input() disabled = false;
  @Input() errorMessage = '';
  @ViewChild('input', { static: true }) input = {} as ElementRef<HTMLInputElement>;

  focused = false;
  disabledState = false;

  private propagateChange: (_: unknown) => unknown = () => null;
  private propagateTouch: (_: unknown) => unknown = () => null;

  @HostBinding('class.ng-invalid') get invalid() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }
  @HostBinding('class.ng-touched') get touched() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControlName) {
      if (changes['value']) {
        this.writeValue(changes['value'].currentValue);
      }
      if (changes['disabled']) {
        this.setDisabledState(changes['disabled'].currentValue);
      }
    }
  }

  displayErrorMessage(): boolean {
    return !!this.errorMessage && (this.formConfig.showErrorMessage ?? true) && !this.disabled;
  }

  clear(): boolean {
    this.writeValue('');
    this.onChange('');

    this.input.nativeElement.focus();

    return false;
  }

  ariaDescribedBy(): string | undefined {
    return this.formConfig.label && this.formConfig.labelHint
      ? `${this.formConfig.id}-hint`
      : undefined;
  }

  writeValue(value: string) {
    this.input.nativeElement.value = value ?? '';
  }

  onChange(value: string) {
    this.propagateChange(value);

    this.valueChange.emit(value);
  }

  onFocus() {
    this.focused = true;
  }

  onBlur(event: FocusEvent) {
    const targetElement = event.relatedTarget as HTMLElement;

    this.focused =
      targetElement?.id === this.formConfig.id ||
      targetElement?.getAttribute('linked-id') === this.formConfig.id;

    this.propagateTouch(event);
  }

  registerOnChange(fn: (_: unknown) => unknown) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => unknown) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledState = isDisabled;
  }
}
