<div class="form-check">
  <input
    #radio
    class="form-check-input"
    type="radio"
    [id]="formConfig.id"
    [attr.name]="formConfig.name"
    [attr.aria-label]="formConfig.ariaLabel"
    [attr.ngbautofocus]="formConfig.autoFocus ? '' : undefined"
    [value]="formConfig.selectedValue"
    (change)="onChange(radio.value)"
    (blur)="onBlur($event)" />
  <label *ngIf="formConfig.label" class="form-check-label" [for]="formConfig.id">{{
    formConfig.label
  }}</label>
</div>
<div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>
