<div class="form-check form-switch form-check-reverse">
  <input
    #toggle
    class="form-check-input"
    type="checkbox"
    role="switch"
    [id]="formConfig.id"
    [attr.name]="formConfig.name"
    [attr.aria-label]="formConfig.ariaLabel"
    [attr.ngbautofocus]="formConfig.autoFocus ? '' : undefined"
    (change)="onChange(toggle.checked)"
    (blur)="onBlur($event)"
    (keydown.enter)="onEnter(toggle.checked)" />
  <label *ngIf="formConfig.label" class="form-check-label" [for]="formConfig.id">{{
    formConfig.label
  }}</label>
</div>
<div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>
