import { ToastMessage, ToastMessageType } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-toast',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  @Input() toastMessages!: ToastMessage[];
  @Output() removeToast = new EventEmitter<ToastMessage>();

  ToastMessageType = ToastMessageType;

  onClose(toastMessage: ToastMessage) {
    this.removeToast.emit(toastMessage);
  }
}
