<ul class="nav nav-underline">
  <ng-container *ngFor="let tab of tabs">
    <li class="nav-item" *ngIf="tab.disabled">
      <a class="nav-link disabled">{{ tab.text }}</a>
    </li>
    <li class="nav-item" *ngIf="!tab.disabled">
      <ng-container
        *ngTemplateOutlet="tab.routerLink ? anchorRouter : anchorUrl; context: { $implicit: tab }">
      </ng-container>
    </li>
  </ng-container>
</ul>

<ng-template #anchorRouter let-tab>
  <a
    class="nav-link"
    [class.active]="tab.text === selected"
    [attr.aria-current]="tab.text === selected ? 'page' : undefined"
    [routerLink]="tab.routerLink"
    [queryParams]="tab.queryParams"
    [fragment]="tab.fragment"
    (click)="onClick(tab.text, true)"
    >{{ tab.text }}</a
  >
</ng-template>

<ng-template #anchorUrl let-tab>
  <a
    class="nav-link"
    [class.active]="tab.text === selected"
    [attr.aria-current]="tab.text === selected ? 'page' : undefined"
    [href]="tab.url ?? '#'"
    (click)="onClick(tab.text, !!tab.url)"
    >{{ tab.text }}</a
  >
</ng-template>
