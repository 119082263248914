import { TabLink } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-tabs',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.scss'
})
export class TabsComponent {
  @Input() tabs!: TabLink[];
  @Input() selected!: string;
  @Output() selectedChange = new EventEmitter<string>();

  onClick(selected: string, navigate: boolean): boolean {
    this.selectedChange.emit(selected);

    return navigate;
  }
}
