import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() disabled = false;
  @Input() leftIconSrc?: string;
  @Input() leftIconAlt = 'left icon';
  @Input() rightIconSrc?: string;
  @Input() rightIconAlt = 'right icon';
  @Input() autoFocus = false;
  @Output() clicked = new EventEmitter<MouseEvent>();

  click(event: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }

    event.stopPropagation();
  }
}
