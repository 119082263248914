import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-busy',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './busy.component.html',
  styleUrl: './busy.component.scss'
})
export class BusyComponent {
  @Input() busy!: boolean | null;
  @Input() message = '';
}
