<header class="modal-header">
  <h3 class="modal-title fs-5">{{ title }}</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
</header>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<footer class="modal-footer">
  <re-button *ngIf="!buttonTemplate" class="re-secondary" [autoFocus]="true" (clicked)="onCancel()"
    >Cancel</re-button
  >
  <ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
</footer>
