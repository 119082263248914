<div class="accordion" [attr.id]="accordionId">
  <div
    class="accordion-item"
    *ngFor="let item of accordionItems"
    (show.bs.collapse)="onToggle()"
    (shown.bs.collapse)="onShow(item, true)"
    (hide.bs.collapse)="onToggle()"
    (hidden.bs.collapse)="onShow(item, false)">
    <h2 class="accordion-header">
      <button
        class="accordion-button collapsed"
        [class.collapsed]="!item.show"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#' + item.id"
        [attr.aria-expanded]="item.show"
        [attr.aria-controls]="item.id"
        (mouseup)="onMouseup($event)">
        <ng-container *ngIf="item.headerText">{{ item.headerText }}</ng-container>
        <ng-container [ngTemplateOutlet]="item.headerTemplate"></ng-container>
      </button>
    </h2>
    <div
      [id]="item.id"
      class="accordion-collapse collapse"
      [class.show]="item.show"
      [attr.data-bs-parent]="accordionId ? '#' + accordionId : undefined">
      <div class="accordion-body">
        <ng-container [ngTemplateOutlet]="item.templateRef"></ng-container>
      </div>
    </div>
  </div>
</div>
