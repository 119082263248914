<re-label [formConfig]="formConfig" [disabled]="disabledState"></re-label>
<button
  class="btn"
  type="button"
  [disabled]="disabledState || (showProgress && !loadError)"
  (click)="input.click()"
  (blur)="onBlur($event)">
  <img
    src="assets/images/cmi-icons/icon-reg_arrow-up-from-bracket.svg"
    alt="upload icon"
    height="16"
    width="16" />
  Choose File
</button>
<input #input type="file" [attr.accept]="formConfig.accept" (input)="onChange($event)" />
<section *ngIf="file || showProgress || loadError">
  <ng-container *ngIf="formConfig.images">
    <div *ngIf="!!srcFile; else emptyImage">
      <img [src]="srcFile" alt="selected image" height="52" width="92" />
    </div>
  </ng-container>
  <ng-container *ngIf="!formConfig.images">
    <div class="re-file-lines">
      <img
        src="assets/images/cmi-icons/icon-reg_file-lines.svg"
        alt="file image"
        height="16"
        width="16" />
    </div>
  </ng-container>
  <div>
    <div>
      <h5 [class.re-with-progress]="showProgress">{{ filename }}</h5>
      <span *ngIf="showProgress" [class.re-error]="loadError">{{ progressPercentage }}</span>
    </div>
    <div class="progress" *ngIf="showProgress">
      <div
        class="progress-bar"
        [class.re-error]="loadError"
        [style.width]="progressPercentage"
        role="progressbar"
        aria-label="Upload progress"
        [attr.aria-valuenow]="progressNow"
        aria-valuemin="0"
        [attr.aria-valuemax]="progressMax"></div>
    </div>
    <div>
      <span class="re-filesize" *ngIf="filesize > 0 && !loadError">{{ filesizeText }}</span>
      <span class="re-error" *ngIf="loadError">{{ loadErrorMessage }}</span>
    </div>
  </div>
  <div>
    <re-icon-button
      class="re-tertiary"
      *ngIf="!showProgress && !loadError"
      [disabled]="disabledState"
      iconSrc="assets/images/cmi-icons/icon-reg_trash.svg"
      iconAlt="delete file"
      (clicked)="onDelete($event)"></re-icon-button>
    <re-icon-button
      class="re-tertiary"
      *ngIf="showProgress || loadError"
      iconSrc="assets/images/cmi-icons/icon-reg_xmark.svg"
      iconAlt="abort upload"
      (clicked)="onAbort($event)"></re-icon-button>
  </div>
</section>
<div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>

<ng-template #emptyImage>
  <div class="re-empty">
    <img
      src="assets/images/cmi-icons/icon-reg_image.svg"
      alt="empty image"
      height="16"
      width="16" />
  </div>
</ng-template>
