<div aria-live="polite" aria-atomic="true" class="toast-container top-0 end-0 p-3">
  <div
    *ngFor="let toastMessage of toastMessages"
    class="toast show"
    [attr.role]="toastMessage.messageType === ToastMessageType.Error ? 'alert' : 'status'"
    [attr.aria-live]="toastMessage.messageType === ToastMessageType.Error ? 'assertive' : 'polite'"
    aria-atomic="true"
    [class.re-success]="toastMessage.messageType === ToastMessageType.Success"
    [class.re-warning]="toastMessage.messageType === ToastMessageType.Warning"
    [class.re-error]="toastMessage.messageType === ToastMessageType.Error"
    [class.re-information]="toastMessage.messageType === ToastMessageType.Information">
    <div class="toast-body">
      <div class="re-header">
        <span>{{ toastMessage.header }}</span>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="onClose(toastMessage)"></button>
      </div>
      <ng-container
        *ngTemplateOutlet="
          toastMessage.htmlMessage ? htmlMessage : message;
          context: { $implicit: toastMessage.message }
        ">
      </ng-container>
      <div *ngIf="toastMessage.link" class="re-link">
        <ng-container *ngIf="toastMessage.url || (toastMessage.routerLink ?? []).length > 0">
          <a
            *ngIf="toastMessage.url"
            class="re-standalone"
            [href]="toastMessage.url"
            (click)="onClose(toastMessage)"
            >{{ toastMessage.link }}</a
          >
          <a
            *ngIf="toastMessage.routerLink"
            class="re-standalone"
            [routerLink]="toastMessage.routerLink"
            [queryParams]="toastMessage.queryParams"
            [fragment]="toastMessage.fragment"
            (click)="onClose(toastMessage)"
            >{{ toastMessage.link }}</a
          >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #message let-message>
  <div class="re-message">{{ message }}</div>
</ng-template>

<ng-template #htmlMessage let-message>
  <div class="re-message" [innerHTML]="message"></div>
</ng-template>
