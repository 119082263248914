import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[reAccordionItem]',
  standalone: true
})
export class AccordionItemDirective {
  @Input() id!: string;
  @Input() header!: string | TemplateRef<unknown>;
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();

  constructor(public templateRef: TemplateRef<unknown>) {}

  get headerText(): string | undefined {
    return typeof this.header === 'string' ? this.header : undefined;
  }
  get headerTemplate(): TemplateRef<unknown> | null {
    return typeof this.header === 'string' ? null : this.header;
  }

  showChanged(show: boolean) {
    this.showChange.emit(show);
  }
}
