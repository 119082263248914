<nav>
  <ng-container *ngFor="let link of links; let last = last">
    <ng-container *ngIf="link.url || (link.routerLink ?? []).length > 0">
      <a *ngIf="link.url" class="re-standalone" [href]="link.url">{{ link.text }}</a>
      <a
        *ngIf="link.routerLink"
        class="re-standalone"
        [routerLink]="link.routerLink"
        [queryParams]="link.queryParams"
        [fragment]="link.fragment"
        >{{ link.text }}</a
      >
    </ng-container>
    <h5 *ngIf="!link.url && (link.routerLink ?? []).length === 0">{{ link.text }}</h5>
    <img
      *ngIf="!last"
      src="assets/images/cmi-icons/icon-reg_chevron-right.svg"
      alt="Chevron Right"
      height="16" />
  </ng-container>
</nav>
