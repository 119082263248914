import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-icon-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input() disabled = false;
  @Input() iconSrc!: string;
  @Input() iconAlt = 'icon';
  @Input() autoFocus = false;
  @Output() clicked = new EventEmitter<MouseEvent>();

  click(event: MouseEvent) {
    if (!this.disabled) {
      this.clicked.emit(event);
    }

    event.stopPropagation();
  }
}
