import { FormConfig } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-checkbox',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, OnChanges {
  @Input() formControlName?: string;
  @Input() formConfig!: FormConfig;
  @Input() value = false;
  @Output() valueChange = new EventEmitter<boolean>();
  @Input() disabled = false;
  @Input() errorMessage = '';
  @ViewChild('checkbox', { static: true }) checkbox = {} as ElementRef<HTMLInputElement>;

  private propagateChange: (_: unknown) => unknown = () => null;
  private propagateTouch: (_: unknown) => unknown = () => null;

  @HostBinding('class.ng-invalid') get invalid() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }
  @HostBinding('class.ng-touched') get touched() {
    return !this.formControlName && this.errorMessage && !this.disabled;
  }

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.formControlName) {
      if (changes['value']) {
        this.writeValue(changes['value'].currentValue);
      }
      if (changes['disabled']) {
        this.setDisabledState(changes['disabled'].currentValue);
      }
    }
  }

  displayErrorMessage(): boolean {
    return !!this.errorMessage && (this.formConfig.showErrorMessage ?? true) && !this.disabled;
  }

  writeValue(checked: boolean) {
    if (checked) {
      this.renderer.setAttribute(this.checkbox.nativeElement, 'checked', '');
      this.checkbox.nativeElement.checked = true;
    } else {
      this.renderer.removeAttribute(this.checkbox.nativeElement, 'checked');
      this.checkbox.nativeElement.checked = false;
    }
  }

  onChange(checked: boolean) {
    this.propagateChange(checked);

    this.valueChange.emit(checked);
  }

  onBlur(event: FocusEvent) {
    this.propagateTouch(event);
  }

  registerOnChange(fn: (_: unknown) => unknown) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: (_: unknown) => unknown) {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.renderer.setAttribute(this.checkbox.nativeElement, 'disabled', '');
    } else {
      this.renderer.removeAttribute(this.checkbox.nativeElement, 'disabled');
    }
  }
}
