import { FormConfig } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-label',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss'
})
export class LabelComponent {
  @Input() formConfig!: FormConfig;
  @Input() disabled = false;

  hintId(): string {
    return `${this.formConfig.id}-hint`;
  }
}
