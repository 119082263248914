<div class="btn-group" role="group" [attr.aria-label]="ariaLabel">
  <button
    *ngFor="let label of buttons"
    type="button"
    class="btn btn-primary"
    [class.active]="selected === label"
    (click)="onClick(label)">
    {{ label }}
  </button>
</div>
