import { ButtonComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 're-modal',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  @Input() target!: string;
  @Input() title!: string;
  @Input() buttonTemplate: TemplateRef<unknown> | null = null;

  constructor(private modal: NgbActiveModal) {}

  onCancel() {
    this.modal.close(true);
  }
}
