<button
  class="btn btn-primary"
  type="button"
  [disabled]="disabled"
  [attr.ngbAutofocus]="autoFocus ? '' : undefined"
  (click)="click($event)">
  <img *ngIf="leftIconSrc" [src]="leftIconSrc" [alt]="leftIconAlt" height="16" width="16" />
  <ng-content></ng-content>
  <img *ngIf="rightIconSrc" [src]="rightIconSrc" [alt]="rightIconAlt" height="16" width="16" />
</button>
