<div [class.re-inline-label]="formConfig.inlineLabel">
  <re-label [formConfig]="formConfig" [disabled]="disabledState"></re-label>
  <div class="re-control">
    <textarea
      #textarea
      class="form-control"
      [id]="formConfig.id"
      [attr.name]="formConfig.name"
      [attr.placeholder]="formConfig.placeholder"
      [attr.aria-label]="formConfig.ariaLabel"
      [attr.aria-describedby]="ariaDescribedBy()"
      [attr.ngbautofocus]="formConfig.autoFocus ? '' : undefined"
      [disabled]="disabledState"
      [rows]="formConfig.rows ?? 5"
      (input)="onChange(textarea.value)"
      (blur)="onBlur($event)"></textarea>
    <div *ngIf="displayErrorMessage()" class="form-text">{{ errorMessage }}</div>
  </div>
</div>
